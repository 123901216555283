import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const IndexPage = () => {

  let indexes = []
  for ( let i = 2; i < 195; i++ ) {
    indexes.push(i)
  }
  indexes = shuffle(indexes)

  let images = []
  let extension = "jpg"
  for ( let i = 0; i < 33; i++ ) {
    extension = (indexes[i] > 161) ? 'gif' : 'jpg'
    images.push( <img key={i} src={`/gifs/${indexes[i]}.${extension}`} alt={`${indexes[i]}.${extension}`} /> )
  }

  return (
    <Layout theme="dark">
      <Seo title="Letterform Variations - Nigel Cottier" />
      
      <Link to="/book">
        <div className="intro-grid">
          {images}
        </div>
      </Link>
    </Layout>
  )
}

export default IndexPage
